<template>
  <md-dialog-confirm
    :md-active.sync="showPopup"
    md-title="Did you mean to leave the page?"
    md-confirm-text="Yup"
    md-cancel-text="Nah"
    @md-cancel="onCancel"
    @md-confirm="onConfirm"
  >
  </md-dialog-confirm>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Fac3dLeaveConfirmation',
  computed: {
    ...mapGetters(['showLeavePageConfirmation']),
    showPopup: {
      get() {
        return this.showLeavePageConfirmation;
      },
      set(val) {
        return this.$store.commit('SET_CONFIRMATION_POPUP', val);
      },
    },
  },
  methods: {
    onCancel() {
      this.$parent.$emit('confirmLeave', false);
      this.$store.commit('SET_CONFIRMATION_POPUP', false);
    },
    onConfirm() {
      this.$parent.$emit('confirmLeave', true);
      this.$store.commit('SET_CONFIRMATION_POPUP', true);
    },
  },
};
</script>
